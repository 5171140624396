import React from 'react'

import slika1 from '../images/products/cocoa_cake_slika1.jpg'
import slika2 from '../images/products/cocoa_cake_slika2.jpg'
import slika3 from '../images/products/cocoa_cake_slika3.jpg'
import slika4 from '../images/products/cocoa_cake_slika4.jpg'
import slika5 from '../images/products/cocoa_cake_slika5.jpg'
import slika6 from '../images/products/cocoa_cake_slika6.jpg'
import slika7 from '../images/products/cocoa_cake_slika7.jpg'


class WaltzCocoaCake extends React.Component{
    render(){
        return(
            <section id="proizvodi" className="hero products-page default-page">
                <div className="container padding-0 margin-0">
                    <div className="columns margin-0 main">
                        <div className="column is-4 static-part is-left padding-0">
                            <div className="content">
                                <div className="title">COCOA WALTZ CAKE</div>
                                <span className="zigzag"></span>
                                <p className="text"></p>
                            </div>
                        </div>{/*STATIC PART END */}
                        <div className="column is-8 content-part is-right padding-0">
                            <div className="columns is-multiline inner margin-0">
                                <div className="column is-6 single-product padding-0">
                                    <img src={slika1} alt="Cocoa Waltz Cake - Cocoa Filling" />
                                </div>
                                <div className="column is-6 single-product padding-0">
                                    <img src={slika2} alt="Cocoa Waltz Cake - Cocoa Filling" />
                                </div>
                                <div className="column is-6 single-product padding-0">
                                    <img src={slika3} alt="Cocoa Waltz Cake - Cocoa Filling" />
                                </div>
                                <div className="column is-6 single-product padding-0">
                                    <img src={slika4} alt="Cocoa Waltz Cake - Cocoa Filling" />
                                </div>
                                <div className="column is-6 single-product padding-0">
                                    <img src={slika5} alt="Cocoa Waltz Cake - Cocoa Filling" />
                                </div>
                                <div className="column is-6 single-product padding-0">
                                    <img src={slika6} alt="Cocoa Waltz Cake - Cocoa Filling" />
                                </div>
                                <div className="column is-6 single-product padding-0">
                                    <img src={slika7} alt="Cocoa Waltz Cake - Cocoa Filling" />
                                </div>
                            </div>
                        </div>{/* PRODUCTS PART END */}
                    </div>{/* COLUMNS END */}
                </div>{/* CONTAINER END */}
            </section>/* PRODUCTS PAGE SECTION END */
        );
    }
}

export default WaltzCocoaCake